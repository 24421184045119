// Variables
$font-name: "Poppins", sans-serif;

$color-black: #000000;
$color-white: #ffffff;
$color-primary: #f15e12;
$color-primary-light: #fff3eb;
$color-accent: #000000;
$color-secondary: #383431;
$color-warn: #cf0d0d;
$color-border: #e0dbdb;
$color-gray: #8b8b8b;
$color-light: #fcfcfc;

$color-black-100: #000000;
$color-black-200: #2a292a;
$color-black-300: #6d6b72;
$color-black-400: #8b8b8b;
$color-black-500: #b0b0b0;
$color-white-100: #ffffff;
$color-white-200: #faf9fc;
$color-white-300: #ebe9f0;

$color-danger: #f44336;
$color-warning: #ffa200;
$color-success: #00db00;

$color-green: #1dbb14;
$color-red: #f44336;
$color-yellow: #ffa200;
$color-blue: #4c56e7;
$color-purple: #b81edf;

$weight-200: 200;
$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;

$size-10: 10px;
$size-11: 11px;
$size-12: 12px;
$size-13: 13px;
$size-14: 14px;
$size-15: 15px;
$size-16: 16px;
$size-17: 17px;
$size-18: 18px;

@mixin transition($transitioneffects) {
    -webkit-transition: $transitioneffects !important;
    -moz-transition: $transitioneffects !important;
    -o-transition: $transitioneffects !important;
    transition: $transitioneffects !important;
}

@mixin transform($transformeffects) {
    -webkit-transform: $transformeffects !important;
    -moz-transform: $transformeffects !important;
    -o-transform: $transformeffects !important;
    transform: $transformeffects !important;
}

@mixin box-shadow($boxshadoweffects) {
    -webkit-box-shadow: $boxshadoweffects !important;
    -moz-box-shadow: $boxshadoweffects !important;
    -o-box-shadow: $boxshadoweffects !important;
    box-shadow: $boxshadoweffects !important;
}
