@import "palette";
@import "variables";

// Set body and html
html,
body {
    min-height: 100%;
}

body {
    margin: 0;
}

// Set headings
@function headings($from: 1, $to: 6) {
    @if $from ==$to {
        @return "h#{$from}";
    } @else {
        @return "h#{$from}," + headings($from + 1, $to);
    }
}

#{headings(1,6)} {
    color: $color-black-100;
}

// Set common values
a {
    color: $color-black-100;
    cursor: pointer;
    outline: none;
    text-decoration: underline;

    &:hover {
        color: $color-black-100;
    }
}

// Hide section
.hide {
    display: none;
}

// Text alignments
.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

// Content alignment
.align-center {
    justify-content: center;
}

.align-left {
    justify-content: flex-start;
}

.align-right {
    justify-content: flex-end;
}

// Borders
.border-top {
    border-top: 1px solid $color-border;
}

.border-bottom {
    border-bottom: 1px solid $color-border;
}

.border-left {
    border-left: 1px solid $color-border;
}

.border-right {
    border-right: 1px solid $color-border;
}

.border-left-dashed {
    border-left: 1px dashed $color-border;
}

.border-top-dashed {
    border-top: 1px dashed $color-border;
}

.border-right-dashed {
    border-right: 1px dashed $color-border;
}

.border-bottom-dashed {
    border-bottom: 1px dashed $color-border;
}

.border-top-black {
    border-top: 1px solid $color-accent;
}

.border-bottom-black {
    border-bottom: 1px solid $color-accent;
}

.border-left-black {
    border-left: 1px solid $color-accent;
}

.border-right-black {
    border-right: 1px solid $color-accent;
}

.margin-left {
    margin-left: 6px !important;
}

.margin-right {
    margin-right: 6px !important;
}

.margin-zero {
    margin: 0px !important;
}

.margin-left-0 {
    margin-left: 0px !important;
}

.margin-right-0 {
    margin-right: 0px !important;
}

.margin-top-0 {
    margin-top: 0px !important;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-top-16 {
    margin-top: 16px !important;
}

.margin-left-16 {
    margin-left: 16px !important;
}

.margin-right-16 {
    margin-right: 16px !important;
}

.margin-bottom-16 {
    margin-bottom: 16px !important;
}

.margin-bottom-24 {
    margin-bottom: 24px !important;
}

.margin-auto-center {
    margin: 0 auto !important;
}

.button-margin-left {
    margin-left: 6px !important;
}

.button-margin-right {
    margin-right: 6px !important;
}

.padding-0 {
    padding: 0px !important;
}

.padding-16 {
    padding: 16px !important;
}

.padding-right-16 {
    padding-right: 16px !important;
}

.padding-left-16 {
    padding-left: 16px !important;
}

.padding-right-46 {
    padding-right: 46px !important;
}

.padding-left-46 {
    padding-left: 46px !important;
}

.desktop {
    padding: 0 130px;
}

.tablet {
    padding: 0 96px;
}

.mobile {
    padding: 0 16px;
}

.strink {
    padding: 0 130px;
}

.expand {
    padding: 0px;
}

.height-40-div {
    height: 40px;
}

.main-container {
    margin: 16px 0px 0px 0px;
    min-height: 600px;
}

.green-underline-small {
    width: 40px;
    height: 4px;
    background-color: $color-primary;
}

.green-underline-regular {
    width: 60px;
    height: 4px;
    background-color: $color-primary;
}

.black-underline-small {
    width: 40px;
    height: 4px;
    background-color: $color-black;
}

.includes-background {
    background-image: url("assets/images/backgrounds/includes.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.apps-background {
    background-image: url("assets/images/backgrounds/apps.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.statistics-background {
    background-image: url("assets/images/backgrounds/statastics.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.gray-background {
    background-color: rgba($color: $color-black, $alpha: 0.03);
}

.team-background {
    background-color: rgba($color: $color-black, $alpha: 0.03);
}

.action-tooltip {
    background-color: $color-black;
    font-size: $size-11;
    font-weight: $weight-500;
    color: $color-white-100;
    line-height: normal;
    margin: 0px !important;
}

.mat-tooltip {
    background-color: $color-accent;
    font-size: $size-11;
    font-weight: $weight-500;
    padding: 4px 2px 4px 2px;
    margin: 5px !important;
}

.black-tooltip {
    background-color: $color-accent;
    font-size: $size-13;
    font-weight: $weight-500;
}

.black-small-tooltip {
    background-color: $color-accent;
    font-size: $size-11;
    font-weight: $weight-500;
    padding: 4px 2px 4px 2px;
    margin: 5px !important;
}

.tag {
    font-size: $size-13;
    font-weight: $weight-600;
    padding: 6px 16px 6px 16px;
    border-radius: 20px;
    vertical-align: middle;
}

.tag-green {
    background-color: $color-green;
    color: $color-white !important;
}

.tag-red {
    background-color: $color-red;
    color: $color-white !important;
}

.tag-yellow {
    background-color: $color-yellow;
    color: $color-white !important;
}

.tag-blue {
    background-color: $color-blue;
    color: $color-white !important;
}

.tag-purple {
    background-color: $color-purple;
    color: $color-white !important;
}

.tag-black {
    background-color: $color-black;
    color: $color-white !important;
}

.chip-tag {
    padding: 4px 10px 4px 10px;
    border-radius: 30px;
    font-weight: $weight-500 !important;
    font-size: $size-11 !important;
    margin-right: 6px;
    letter-spacing: 1px;
}

.activated-tag {
    background-color: #00db00;
    color: $color-white !important;
}

.deactivated-tag {
    background-color: #e4dae2;
    color: $color-accent !important;
}

.paid-tag {
    background-color: #000000;
    color: $color-white !important;
}

.unpaid-tag {
    background-color: #ebbb1f;
    color: $color-white !important;
}

.pending-tag {
    background-color: #ebbb1f;
    color: $color-white !important;
}

.idle-tag {
    background-color: #571ec2;
    color: $color-white !important;
}

.halt-tag {
    background-color: #f44336;
    color: $color-white !important;
}

.waiting-tag {
    background-color: #ffa200;
    color: $color-white !important;
}

.running-tag {
    background-color: #eb1793;
    color: $color-white !important;
}

.upcoming-tag {
    background-color: #b81edf;
    color: $color-white !important;
}

.offline-tag {
    background-color: #3669f4;
    color: $color-white !important;
}

.stopped-tag {
    background-color: #f44336;
    color: $color-white !important;
}

.moving-tag {
    background-color: #00db00;
    color: $color-white !important;
}

.ongoing-tag {
    background-color: #4c56e7;
    color: $color-white !important;
}

.on-tag {
    background-color: #00db00;
    color: $color-white !important;
}

.off-tag {
    background-color: #f44336;
    color: $color-white !important;
}

.completed-tag {
    background-color: #00db00;
    color: $color-white !important;
}

.cancelled-tag {
    background-color: #f44336;
    color: $color-white !important;
}

.extended-tag {
    background-color: #18dfdf;
    color: $color-white !important;
}

.verified-icon {
    color: #00db00;
}

.pending-icon {
    color: #ebbb1f;
}

.error-icon {
    color: #f44336;
}

.offline-icon {
    color: #3649f4;
}

.stopped-icon {
    color: #f44336;
}

.moving-icon {
    color: #00db00;
}

.round-flat-button {
    border-radius: 30px !important;
    font-weight: $weight-600 !important;
    font-size: $size-13 !important;
    color: $color-white-100 !important;

    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}

.square-flat-button {
    border-radius: 4px !important;
    font-weight: $weight-600 !important;
    font-size: $size-13 !important;
    color: $color-white-100 !important;

    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}

.square-stroked-button {
    border-radius: 4px !important;
    font-weight: $weight-600 !important;
    font-size: $size-13 !important;

    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}

.full-square-flat-button {
    min-width: 100% !important;
    width: 100% !important;
    border-radius: 4px !important;
    font-weight: $weight-600 !important;
    font-size: $size-13 !important;
    color: $color-white-100 !important;
    text-transform: uppercase !important;
    height: 46px;

    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}

.full-square-stroked-button {
    min-width: 100% !important;
    width: 100% !important;
    border-radius: 4px !important;
    font-weight: $weight-600 !important;
    font-size: $size-13 !important;
    text-transform: uppercase !important;
    height: 46px;

    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}

.round-stroked-button {
    border-radius: 30px !important;
    font-weight: $weight-500 !important;
    font-size: $size-13 !important;
    border: 1px solid $color-primary !important;

    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
    }
}

.store-button {
    button {
        text-align: left;
        padding: 10px;
        margin-bottom: 10px;

        span {
            line-height: 0px;
        }

        img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            margin-right: 5px;
        }

        table {
            width: 100%;

            tr {
                td {
                    vertical-align: middle;
                }
            }
        }
    }
}

.input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    border-bottom: 1px solid $color-gray;
    padding: 20px 16px 0px 16px;
    border-radius: 3px 3px 0px 0px;
    background-color: rgba($color: $color-primary, $alpha: 0.1);

    h4 {
        font-size: $size-15;
        font-weight: $weight-600;
        color: $color-black-100;
        margin: 0px;
        padding: 6px 10px 6px 10px;
        border-radius: 10px;
    }
}

.mat-form-field {
    width: 100%;
    margin: 6px 0px 6px 0px;

    mat-label {
        font-weight: $weight-500;
        font-size: $size-15;
    }

    .mat-error {
        font-weight: $weight-700;
        font-size: $size-11;

        strong {
            font-weight: $weight-900;
        }
    }

    .mat-hint {
        font-weight: $weight-700;
        font-size: $size-11;

        strong {
            font-weight: $weight-900;
        }
    }

    input {
        width: 100%;
        margin-top: 4px !important;
        font-weight: $weight-700;
        font-size: $size-15;
        color: $color-black-100;
    }

    .mat-select {
        width: 100%;
        font-weight: $weight-700 !important;
        font-size: $size-15 !important;
        color: $color-black-100;
        margin-top: 4px !important;
    }

    textarea {
        width: 100%;
        font-weight: $weight-700;
        font-size: $size-15;
        color: $color-black-100;
        resize: none !important;
    }
}

.mat-select-panel {
    .mat-option {
        font-weight: $weight-500 !important;
        font-size: $size-14 !important;
    }
}

.mat-form-field-appearance-fill {
    .mat-form-field-flex {
        background-color: rgba($color: $color-primary, $alpha: 0.1);
    }
}

.mat-form-field-appearance-outline {
    .mat-form-field-outline {
        color: $color-accent;
    }
}

.mat-checkbox {
    label {
        font-weight: $weight-600;
        font-size: $size-14;
    }
}

.mat-simple-snackbar {
    font-size: $size-13;
    font-weight: $weight-500;
}

.success-snackbar {
    background-color: $color-accent;
    margin-bottom: 0px !important;

    span {
        color: $color-white-100;
    }

    button {
        color: $color-white-100;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}

.error-snackbar {
    background-color: $color-accent;
    margin-bottom: 0px !important;

    span {
        color: $color-white-100;
    }

    button {
        border: 1px solid $color-black;
        color: $color-white-100;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}

.white-snackbar {
    background-color: $color-white;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));

    span {
        color: $color-black-100;
    }

    button {
        span {
            color: $color-primary;
        }

        color: $color-primary;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}

.primary-snackbar {
    background-color: $color-primary;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));

    span {
        color: $color-white-100;
    }

    button {
        span {
            color: $color-white;
        }

        color: $color-white;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}

.page-title {
    margin-top: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 121, 10, 0.1);
    padding: 46px 0px 46px 0px;
    h1 {
        color: $color-black;
        font-size: 2em;
        font-weight: $weight-700;
        margin: 0px;
        text-align: center;
    }
    h2 {
        text-align: center;
    }
}

.section-div {
    .includes {
        .section-header {
            text-align: center;

            h1 {
                font-weight: $weight-700;
                color: $color-white;
                margin: 0px;
            }

            small {
                font-size: $size-12;
                font-weight: $weight-500;
                color: $color-white;
                margin: 0px;
            }

            span {
                color: $color-primary;
            }
        }
    }

    .apps {
        .section-header {
            text-align: center;

            h1 {
                font-weight: $weight-700;
                color: $color-white;
                margin: 0px;
            }

            small {
                font-size: $size-12;
                font-weight: $weight-500;
                color: $color-white;
                margin: 0px;
            }

            .double-line {
                border-color: $color-white;
            }
        }
    }

    .section-sub-header {
        font-weight: $weight-600;
        color: $color-black;
        margin: 0px;
    }

    .section-header {
        text-align: center;

        h1 {
            font-weight: $weight-700;
            color: $color-black-100;
            margin: 0px;
        }

        h2 {
            font-weight: $weight-600;
            color: $color-black-200;
            margin: 10px 0px 0px 0px;
        }

        h3 {
            font-weight: $weight-500;
            color: $color-black-200;
            margin: 0px;
        }

        small {
            font-size: $size-12;
            font-weight: $weight-500;
            color: $color-gray;
            margin: 0px;
        }

        .double-line {
            width: 50px;
            margin: 16px auto;
            border-bottom: 1px solid $color-primary;
        }

        span {
            color: $color-primary;
        }

        .color-white {
            color: $color-white !important;
        }
    }

    .section-body {
        padding: 30px 0px 30px 0px;
        .includes-box {
            .includes-item-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .includes-item {
                    margin: 0 auto;
                    margin-right: 16px;
                    margin-left: 16px;
                    text-align: center;
                    cursor: pointer;
                    width: 100px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    border-radius: 50%;
                    background-color: rgba($color: $color-white, $alpha: 0.1);

                    .mat-icon {
                        font-size: 28px;
                        width: unset;
                        height: unset;
                        vertical-align: middle;
                        font-weight: $weight-500;
                        color: $color-primary;
                        margin-bottom: 8px;
                    }

                    label {
                        font-size: $size-18;
                        font-weight: $weight-600;
                        color: $color-white;
                    }

                    &:hover {
                        background-color: $color-primary;

                        .mat-icon {
                            color: $color-white;
                        }

                        label {
                            color: $color-white;
                        }
                    }

                    .item-icon {
                        img {
                            color: $color-primary;
                        }
                    }
                }

                .include-title {
                    color: $color-white;
                    font-weight: $weight-600;
                    margin-top: 8px;
                    text-align: center;
                }
            }
        }

        .rental-services {
            .service-box {
                text-align: center;

                .service-icon {
                    margin: 0 auto;
                    width: 60px;
                    height: 60px;
                    border: 1px dashed $color-black;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                h3 {
                    font-weight: $weight-600;
                    color: $color-black;
                    margin: 16px 0px 10px 0px;
                }

                p {
                    font-size: $size-13;
                    font-weight: $weight-500;
                    color: $color-gray;
                }

                &:hover {
                    .mat-icon {
                        color: $color-primary;
                    }
                }
            }
        }
        .contact-form {
            .contact-box {
                h2 {
                    font-weight: $weight-600;
                    color: $color-black;
                    margin: 0px 0px 8px 0px;
                }

                small {
                    font-size: $size-12;
                    font-weight: $weight-600;
                    color: $color-gray;
                }

                table {
                    border-spacing: 0px;

                    tr {
                        td {
                            vertical-align: top;
                            padding-bottom: 6px;

                            .mat-icon {
                                width: unset;
                                height: unset;
                                line-height: 0px;
                                font-size: $size-14;
                                vertical-align: middle;
                                color: $color-gray;
                                margin-right: 10px;
                            }

                            p {
                                margin: 0px 0px 0px 0px;
                                color: $color-black;
                                font-weight: $weight-600;
                                font-size: $size-13;

                                strong {
                                    color: $color-black;
                                    font-weight: $weight-500;
                                    font-size: $size-13;
                                }

                                a {
                                    font-size: $size-13;
                                    color: $color-black;
                                    font-weight: $weight-500;
                                    text-decoration: none;
                                    outline: none;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .details-div {
                margin: 0px 0px 16px 0px;

                h3 {
                    font-weight: $weight-600;
                    color: $color-black;
                    margin: 0px 0px 8px 0px;
                }

                table {
                    border-spacing: 0px;

                    tr {
                        td {
                            vertical-align: top;
                            padding-bottom: 6px;

                            .mat-icon {
                                width: unset;
                                height: unset;
                                line-height: 0px;
                                font-size: $size-14;
                                vertical-align: middle;
                                color: $color-gray;
                                margin-right: 10px;
                            }

                            address {
                                margin: 0px 0px 0px 0px;
                                color: $color-black;
                                font-weight: $weight-500;
                                font-style: normal;
                                font-size: $size-13;

                                strong {
                                    color: $color-black;
                                    font-weight: $weight-500;
                                    font-size: $size-13;
                                }

                                a {
                                    font-size: $size-13;
                                    color: $color-black;
                                    font-weight: $weight-500;
                                    text-decoration: none;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-pagination {
    background-color: $color-white;
    @include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    overflow: hidden;

    .mat-paginator {
        width: 100%;

        .mat-paginator-outer-container {
            .mat-paginator-container {
                .mat-paginator-page-size {
                    .mat-paginator-page-size-label {
                        font-weight: $weight-600;
                        font-size: $size-11;
                        color: $color-black-100;
                    }

                    .mat-paginator-page-size-select {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                }

                .mat-paginator-range-actions {
                    .mat-paginator-range-label {
                        font-weight: $weight-600;
                        font-size: $size-13;
                        color: $color-black-100;
                    }

                    .mat-icon-button {
                        .mat-paginator-icon {
                            color: $color-border;
                        }
                    }

                    .mat-icon-button:not([disabled]) {
                        .mat-paginator-icon {
                            color: $color-accent;
                        }
                    }
                }
            }
        }
    }
}

.document {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $weight-600;
        color: $color-black-100;
        line-height: normal;
        margin: 0px 0px 4px 0px;
        b {
            font-weight: $weight-600;
        }
    }
    p {
        font-size: $size-13;
        font-weight: $weight-400;
        color: $color-black-100;
        line-height: 30px;
        b {
            font-weight: $weight-600;
        }
        strong {
            font-weight: $weight-600;
            color: $color-gray;
        }
        a {
            font-size: $size-13;
            font-weight: $weight-500;
            color: $color-blue;
            outline: none;
            text-decoration: none;
        }
    }
    ol {
        font-size: $size-15;
        ul {
            padding: 0px 0px 0px 16px;
            margin: 0px;
            li {
                padding: 0px 0px 0px 0px;
                margin: 8px 0px 8px 0px;
                h3 {
                    font-size: $size-13;
                    font-weight: $weight-600;
                    color: $color-black-100;
                    line-height: normal;
                    margin: 0px 0px 4px 0px;
                    text-transform: uppercase;
                }
                font-size: $size-13;
                font-weight: $weight-400;
                color: $color-black-100;
                line-height: 30px;
                b {
                    font-weight: $weight-600;
                }
                a {
                    font-size: $size-13;
                    font-weight: $weight-500;
                    color: $color-blue;
                    outline: none;
                    text-decoration: none;
                }
            }
        }
    }
}
small {
    font-size: $size-11;
    font-weight: $weight-400;
    color: $color-black;
    b {
        font-weight: $weight-600;
    }
}

.mat-dialog-container {
    border-radius: 10px !important;
    padding: 0px 0px 0px 0px !important;
}

.modal {
    padding: 0px;

    .modal-header {
        border-bottom: 1px solid $color-gray;
        margin: 24px 24px 0px 24px;

        .modal-title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 4px;

            h1 {
                font-weight: $weight-600;
                color: $color-black-100;
                margin: 0px 0px 0px 0px;
                vertical-align: bottom;
            }

            h2 {
                font-weight: $weight-600;
                color: $color-black-100;
                margin: 0px 0px 0px 0px;
                vertical-align: bottom;
            }

            h3 {
                font-weight: $weight-600;
                color: $color-black-100;
                margin: 0px 0px 0px 0px;
                vertical-align: bottom;
            }

            .div-spacer {
                flex: 1 1 auto;
            }

            .mat-icon-button {
                width: 34px;
                height: 34px;
                line-height: unset;

                .mat-icon {
                    color: $color-accent;
                    font-size: 20px;
                    font-weight: $weight-700;
                }
            }
        }
    }

    .modal-body {
        padding: 10px 22px 10px 24px;
        margin-right: 2px;

        .modal-message {
            margin-top: 16px;

            h2 {
                font-weight: $weight-500;
                color: $color-black-100;
            }

            h3 {
                font-weight: $weight-600;
                color: $color-black-100;
            }

            h4 {
                font-weight: $weight-500;
                color: $color-black-100;
            }
        }

        .instructions {
            ul {
                margin: 0px;
                padding-inline-start: 16px;

                li {
                    padding: 0px;
                    font-size: $size-11;
                    font-weight: $weight-600;
                    color: $color-black-300;
                }
            }
        }
    }

    .modal-footer {
        text-align: right;
        padding: 4px 24px 24px 24px;
    }
}
.backdrop-blur {
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.43);
}

.modal-body-scroller {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-body-scroller::-webkit-scrollbar {
    width: 10px;
    position: absolute;
    top: 0px;
}

.modal-body-scroller::-webkit-scrollbar-track {
    background: $color-white;
}

.modal-body-scroller::-webkit-scrollbar-thumb {
    background: rgba($color: $color-accent, $alpha: 0.1);
    border-radius: 30px;
}

.modal-body-scroller::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $color-accent, $alpha: 0.2);
}

// .mat-select-panel-scroller {
.mat-select-panel {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.mat-select-panel::-webkit-scrollbar {
    width: 10px !important;
    position: absolute;
    top: 0px;
}

.mat-select-panel::-webkit-scrollbar-track {
    background: $color-white !important;
}

.mat-select-panel::-webkit-scrollbar-thumb {
    background: rgba($color: $color-accent, $alpha: 0.1) !important;
    border-radius: 30px !important;
}

.mat-select-panel::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $color-accent, $alpha: 0.2) !important;
}
// }

.custom-loader {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9;
    background-color: rgba($color: $color-white, $alpha: 0.9);

    .loader-text {
        position: sticky;
        top: 80px;
        display: flex;
        width: 100%;
        padding-top: 14%;
        justify-content: center;
        align-items: center;

        h4 {
            font-weight: $weight-600;
            color: $color-black-100;
            text-transform: uppercase;
            margin: 0px 0px 0px 10px;
        }

        .mat-spinner {
            circle {
                stroke: $color-primary;
            }
        }
    }
}

// Tabs group
.tab-group {
    .mat-tab-header {
        border-bottom: none;
        .mat-tab-list {
            margin: 0 auto;
            flex-grow: 0;
            .mat-tab-labels {
                border: 2px solid $color-black-300;
                background-color: rgb(251, 245, 233);
                border-radius: 30px;
                @include box-shadow(0px 2px 10px 0px rgba(0, 0, 0, 0.1));
                padding: 4px 4px 4px 4px;
                margin: 10px;
            }
        }
    }
    .mat-tab-label {
        margin: 0px 0px 0px 0px;
        padding: 0px;
        border-radius: 30px;
        font-size: $size-14;
        font-weight: $weight-600;
        color: $color-black;
        opacity: 1;
        &:hover {
            background-color: rgb(252, 234, 197);
        }
    }
    .mat-tab-label-active {
        color: $color-white-100;
        font-weight: $weight-700;
        background-color: $color-accent;
        border-bottom: none;
        opacity: 1;
        &:hover {
            background-color: $color-black-200;
            color: $color-white;
        }
    }
    .mat-ink-bar {
        opacity: 1;
        background-color: transparent !important;
    }
    .tab-container {
        padding: 16px 0px 16px 0px;
    }
}

.showcase-carousel {
    .slick-dots li button::before {
        font-size: 18px;
        bottom: unset;
        border-radius: 10px;
        width: 22px !important;
        height: 8px !important;
        background-color: $color-black;
        content: "";
    }
    .slick-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px !important;
        width: 36px !important;
        bottom: unset;
        top: 42%;
        @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.2));
        background-color: $color-white;
        border-radius: 50%;
        transform: unset;
        vertical-align: middle;
        &:hover {
            background-color: $color-white;
        }
        &:focus {
            background-color: $color-white;
        }
        &:active {
            background-color: $color-white;
        }
    }
    .slick-prev {
        left: 0px;
        margin-right: 8px;
        z-index: 999;
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            display: inline-block;
            transform: scale(-1, 1);
        }
    }
    .slick-next {
        right: 0px;
        z-index: 999;
        &::before {
            color: $color-black-100 !important;
            content: "\279C";
            display: inline-block;
        }
    }
    .slick-slide {
        margin-right: 26px;
        height: 460px;
    }
    .slick-track {
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin: auto !important;
    }
    .slick-disabled {
        display: none !important;
    }
}
.showcase-carousel-mobile {
    .slick-slide {
        height: 280px;
    }
    .slide {
        .slider-image {
            img {
                object-fit: cover;
            }
        }
    }
}
